import {useEffect, useState} from "react";
import { getUnitsByCompany, deleteUnit } from "../../services/UnitService";
import {getCompanyId} from "../../config";
import {Link} from "react-router-dom";
import {Unit} from "../../interfaces/IUnit";

const UnitList = () => {
    const [state, setState] = useState({
        units: [] as Unit[],
        loading: false,
        isDeleted: false,
    });

    const __init = async () => {
        const companyId = await getCompanyId()
        const units = await getUnitsByCompany(companyId);
        setState({...state, units})
    }

    const onDelete = async (id: number) => {
        const isDeleted = await deleteUnit(id);

        if (isDeleted){
            setState({...state, isDeleted: true});
        }
    };

    useEffect(()=> {
        __init()
    },[state.isDeleted]);

    const renderUnits = () => {
        return (
            state.units.map((unit: any) => (
                <tr key={unit.id}>
                    <td>{unit.id}</td>
                    <td>{unit.name}</td>
                    <td>{unit.price}</td>
                    <td>{unit.persons}</td>
                    <td>{unit.capacity}</td>
                    <td>
                        <span className={` fas fa-circle ${ unit.availability == 1 ? 'text-success' : 'text-danger'}`}></span>
                    </td>
                    <td>{unit.max_shift}</td>
                    <td>{unit.start_time}</td>
                    <td>{unit.end_time}</td>
                    <td>
                        <div className="btn-group btn-group-sm">
                            <Link to={`/unit/${unit.id}`} className="btn btn-warning"><i className="fas fa-edit"></i></Link>
                            <a href="javascript:void(0)" className="btn btn-danger" onClick={() => onDelete(unit.id)}><i className="fas fa-trash"></i></a>
                        </div>
                    </td>
                </tr>
            ))
        );
    }

    return (
        <div className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title text-uppercase">Unit list</h3>
                            </div>

                            <div className="card-body table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Persons</th>
                                        <th>Capacity</th>
                                        <th>Availability</th>
                                        <th>Max Shift</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {renderUnits()}
                                    </tbody>
                                </table>
                            </div>

                            <div className="card-footer">
                                <Link to={'/unit/create'} className="btn btn-info">
                                    Create new
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnitList