import {useEffect, useState} from "react";
import {getCompanyId} from "../../config";
import {getCompanyByUser2} from "../../services/CompaniesService";
import moment from "moment";
import {getBookingRequestsByMonth} from "../../services/BookingRequest";
import bookingRequestList from "./BookingRequestList";
import DatePicker from "react-datepicker";
import {Link} from "react-router-dom";
import BookingRequestList from "./BookingRequestList";
import {Company} from "../../interfaces/ICompany";

const BookingRequestMonthList = () => {
    const [state, setState] = useState({
        date: moment().format('yyyy-MM-DD'),
        bookingRequests: [],
        companyDetails: {} as Company
        // statusUpdated: false
    });

    useEffect(()=> {
        const __init = async () => {
            const data = {
                date: state.date
            }
            const bookingRequests = await getBookingRequestsByMonth(data);

            let user: any = localStorage.getItem("user");
            let { id } = JSON.parse(user);

            const companyDetails = await getCompanyByUser2(id)

            setState({...state, bookingRequests, companyDetails})
        }

        __init()
    },[state.date]);

    const onDateChange = async (val: any) => {
        setState({
            ...state,
            date: moment(val).format('yyyy-MM-DD')
        });
    };

    // const updateStatus = () => {
    //     setState({...state, statusUpdated: true })
    // }

    return (
        <>
            <div className={'container-fluid'}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <div className={'d-flex align-items-center p-2'}>
                            <span className="card-title m-3">Booking Request Month List</span>

                            <span>
                                <DatePicker
                                    className={'form-control'}
                                    onChange={onDateChange}
                                    onSelect={onDateChange}
                                    selected={new Date(state.date)}
                                    dateFormat={'MM.yyyy'}
                                    showMonthYearPicker
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <BookingRequestList bookingRequests={state.bookingRequests} companyDetails={state.companyDetails}/>
        </>
    );
}

export default BookingRequestMonthList;