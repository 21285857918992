import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import DashboardLayout from "../views/layout/DashboardLayout";
import Login from "../views/Login";
import React from "react";
import {PrivateRoute} from "./PrivateRoute";
import {isAuthenticated} from "../services/AuthenticationService";
import EditCompany from "../components/companies/EditCompany";
import ResetPassword from "../views/ResetPassword";
import ProductList from "../components/products/ProductList";
import EditProduct from "../components/products/EditProduct";
import Dashboard from "../views/Dashboard";
import UnitList from "../components/units/UnitList";
import EditUnit from "../components/units/EditUnit";
import CreateUnit from "../components/units/CreateUnit";
import CustomPriceList from "../components/customPrices/CustomPriceList";
import EditCustomPrice from "../components/customPrices/EditCustomPrice";
import CreateCustomPrice from "../components/customPrices/CreateCustomPrice";
import FreeCityList from "../components/freeCities/FreeCityList";
import EditFreeCity from "../components/freeCities/EditFreeCity";
import CreateFreeCity from "../components/freeCities/CreateFreeCity";
import UnitAvailabilityList from "../components/unitAvailability/UnitAvailabilityList";
import EditUnitAvailability from "../components/unitAvailability/EditUnitAvailability";
import CreateUnitAvailability from "../components/unitAvailability/CreateUnitAvailability";
import CreatePrice from "../components/prices/CreatePrice";
import CompanyDetails from "../components/companies/CompanyDetails";
import ForgotPassword from "../views/ForgotPassword";
import ResetPasswordLinkExpired from "../views/ResetPasswordLinkExpired";
import EditPrice from "../components/prices/EditPrice";
import MonthList from "../components/bookings/MonthList";
import EditBooking from "../components/bookings/EditBooking";
import AddExtraService from "../components/bookings/AddExtraService";
import AddAJob from "../components/bookings/AddAJob";
import AddABlock from "../components/bookings/AddABlock";
import BookingRequestMonthList from "../components/bookingRequests/BookingRequestMonthList";
import EditBookingRequest from "../components/bookingRequests/EditBookingRequest";

const RouteList = () => {
    return (
        <Routes>
            <Route path="/"  element={
                <PrivateRoute isAuthenticated={isAuthenticated()}>
                    <DashboardLayout />
                </PrivateRoute>
            }>

                {/* Companies */}
                <Route path="/companies"  element={<CompanyDetails />}/>
                <Route path="/companies/:id"  element={<EditCompany />}/>

                {/* Products */}
                <Route path="/products"  element={<ProductList />}/>
                <Route path="/product/:id"  element={<EditProduct />}/>

                {/* Units */}
                <Route path="/units"  element={<UnitList />}/>
                <Route path="/unit/:id"  element={<EditUnit />}/>
                <Route path="/unit/create"  element={<CreateUnit />}/>

                {/* Custom Prices */}
                <Route path="/custom-prices"  element={<CustomPriceList />}/>
                <Route path="/custom-prices/:id"  element={<EditCustomPrice />}/>
                <Route path="/custom-prices/create"  element={<CreateCustomPrice />}/>

                {/* Free Cities */}
                <Route path="/free-cities"  element={<FreeCityList />}/>
                <Route path="/free-cities/:id"  element={<EditFreeCity />}/>
                <Route path="/free-cities/create"  element={<CreateFreeCity />}/>

                {/* Unit Availability */}
                <Route path="/units-availability"  element={<UnitAvailabilityList />}/>
                <Route path="/units-availability/:id"  element={<EditUnitAvailability />}/>
                <Route path="/units-availability/create"  element={<CreateUnitAvailability />}/>

                {/* Product Price Details */}
                <Route path="/price/create/:id"  element={<CreatePrice />}/>
                <Route path="/price/:id"  element={<EditPrice />}/>

                {/* Bookings */}
                <Route path="/month"  element={<MonthList />}/>
                <Route path="/booking/:id"  element={<EditBooking />}/>
                <Route path="/booking/extra-service/:id"  element={<AddExtraService />}/>
                <Route path="/booking/add-a-job"  element={<AddAJob />}/>
                <Route path="/booking/add-a-block"  element={<AddABlock />}/>

                {/* Booking Requests */}
                <Route path="/booking-request-month"  element={<BookingRequestMonthList />}/>
                <Route path="/booking-request/:id"  element={<EditBookingRequest />}/>

            </Route>

            <Route path="/login"  element={
                isAuthenticated() ? <Navigate to="/companies" replace={true} /> : <Login />
            }/>
            {/* Forgot Password */}
            <Route path="/forgot-password"  element={<ForgotPassword />}/>
            {/* Forgot Password */}
            <Route path="/reset-password/:token"  element={<ResetPassword />}/>
            {/* Reset Password Link Expired*/}
            <Route path="/reset-password/expired"  element={<ResetPasswordLinkExpired />}/>
        </Routes>
    )
}

export default RouteList;