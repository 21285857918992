import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import { getProducts, deleteProduct } from "../../services/ProductsService";
import {getCompanyProductPrices} from "../../services/CompanyProductPriceService";
import {getCompanyId} from "../../config";
import {Product} from "../../interfaces/IProduct";
import {CompanyProductPrice} from "../../interfaces/ICompanyProductPrice";

const ProductList = () => {
    const [state, setState] = useState({
        products: [] as Product[],
        companyProductPrices: [] as CompanyProductPrice[],
        companyId : null,
        loading: false,
        isDeleted: false,
    });

    const __init = async () => {
        const companyProductPrices = await getCompanyProductPrices()
        const products = await getProducts();
        const companyId = await getCompanyId();
        setState({...state, products, companyId, companyProductPrices})
    }

    const onDelete = async (id: number) => {
        const isDeleted = await deleteProduct(id);

        if (isDeleted){
            setState({...state, isDeleted: true});
        }
    };

    useEffect(()=> {
        __init()
    },[state.isDeleted]);

    const renderProducts = () => {
        return (
            state.products.map((product: any) => {

                const companyProductPrice = state.companyProductPrices.find(
                    (price: CompanyProductPrice) => price.product_id === product.id && price.company_id === state.companyId
                );

                return (
                    <tr key={product.id}>
                        <td>{product.id}</td>
                        <td>{product.name}</td>
                        <td>{product.description}</td>
                        <td>{product.duration}</td>
                        <td>
                            <div className="btn-group btn-group-sm">
                                {companyProductPrice && (<Link to={`/price/${product.id}`} className="btn btn-warning">
                                    <i className="fas fa-edit"></i>
                                </Link>
                                )}
                                {!companyProductPrice && (
                                    <Link to={`/price/create/${product.id}`} className="btn btn-secondary">
                                        <i className="fas fa-plus"></i>
                                    </Link>
                                )}
                            </div>
                        </td>
                    </tr>
                )
            })
        );
    }

    return (
        <div className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title text-uppercase">Product list</h3>
                            </div>

                            <div className="card-body table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Product name</th>
                                        <th>Description</th>
                                        <th>Duration</th>
                                        <th>Price</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {renderProducts()}
                                    </tbody>
                                </table>
                            </div>

                            {/*<div className="card-footer">*/}

                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductList;